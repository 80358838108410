import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from "../../components/BetterBox";
import BetterAlerts from "../../components/BetterAlerts";
import BetterCircularProgress from "../../components/BetterCircularProgress";
import { forgotPassword } from "../../crud/auth.crud";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert2";

class ForgotPassword extends Component {
    constructor() {
        super();

        this.state = {
            email: "",
            loading: false,
            submitted: false,
        };
    }

    onSubmit = () => {
        if (!this.state.email) {
            this.setState({ errors: ["O e-mail é uma informação obrigatória."] });
            return;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) {
            this.setState({ errors: ["Você precisa informar um e-mail válido."] });
            return;
        }

        this.setState({ submitted: true });

        forgotPassword({ email: this.state.email })
            .then(async (res) => {
                window.scrollTo(0, 0);

                if (res.data.error) {
                    this.setState({ submitted: false });

                    Swal.fire("Ops!", res.data.error, "error");
                } else {
                    await Swal.fire({
                        icon: "success",
                        title: "Tudo certo!",
                        text: "Foi enviado um e-mail para redefinição de senha.",
                        showConfirmButton: false,
                        timer: 1500,
                    });

                    window.location.href = "/auth/login";
                }
            })
            .catch((e) => {
                Swal.fire("Ops!", e.response?.data?.errors[0] ?? "Parece que houve um problema ao enviar o e-mail. Por favor, entre em contato com o suporte.", "error");

                this.setState({ submitted: false });
            });
    };

    render() {
        return (
            <div style={{ margin: "50px 0px 50px 0px" }}>
                <div className="row" style={{ margin: "0" }}>
                    <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div className="row">
                            <div className="col-sm-12">
                                <center>
                                    <img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: "160px", height: "auto", marginTop: "35px", marginBottom: "35px" }} />

                                    <p style={{ padding: "10px 50px 15px 50px", fontSize: "18px", color: "#424242", fontWeight: "500" }}>
                                        <b>Olá!</b> Para recuperar seu acesso, por favor informe um e-mail.
                                    </p>
                                </center>
                            </div>

                            <div className="col-sm-12">
                                <BetterCircularProgress loading={this.state.loading}>
                                    <div>
                                        <div className="col-sm-12">
                                            <BetterAlerts errors={this.state.errors} />
                                        </div>

                                        <div>
                                            <div className="col-sm-12">
                                                <BetterBox title="Digite um e-mail." subtitle="Se o e-mail existir no sistema será enviado um link de redefinição de senha.">
                                                    <div className="col-sm-12">
                                                        <div className="form-group fg-line">
                                                            <TextField type="email" name="email" label="E-mail *" margin="normal" variant="outlined" onBlur={(event) => this.setState({ email: event.target.value })} onChange={(event) => this.setState({ email: event.target.value })} />
                                                        </div>
                                                    </div>
                                                </BetterBox>
                                            </div>

                                            <div className="col-sm-12">
                                                <center>
                                                    <br />
                                                    <Button className="btn btn-primary btn-lg btn-bold" onClick={() => this.onSubmit()}>
                                                        <i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Enviar
                                                    </Button>

                                                    <br />
                                                    <br />

                                                    <Link to="/auth/login" className="btn btn-secondary btn-lg btn-bold">
                                                        Voltar
                                                    </Link>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </BetterCircularProgress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, null)(ForgotPassword);
