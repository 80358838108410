import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "./dashboard/DashboardPage";
import { LayoutSplashScreen } from "../../../_metronic";

const AdminPage = lazy(() => import("./admin/AdminPage"));
const GrupoUsuarioPage = lazy(() => import("./grupo-usuario/GrupoUsuarioPage"));
const EtapaPage = lazy(() => import("./etapa/EtapaPage"));
const FornecedorPage = lazy(() => import("./fornecedor/FornecedorPage"));
const ClientePage = lazy(() => import("./cliente/ClientePage"));
const ObraPage = lazy(() => import("./obra/ObraPage"));
const SolicitacaoPage = lazy(() => import("./solicitacao/SolicitacaoPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/dashboard" />

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/grupo-usuario" component={GrupoUsuarioPage} />
                <Route path="/etapa" component={EtapaPage} />
                <Route path="/fornecedor" component={FornecedorPage} />
                <Route path="/cliente" component={ClientePage} />
                <Route path="/obra" component={ObraPage} />
                <Route path="/solicitacao" component={SolicitacaoPage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
