import React, { Component } from "react";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { getDashboard } from "../../../crud/dashboard.crud";
import BetterCircularProgress from "../../../components/BetterCircularProgress";

export default class DashboardPage extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            error: false,
            data: null,
        };
    }

    componentDidMount = () => {
        this.setState({ loading: true });

        getDashboard()
            .then((res) => {
                this.setState({ data: res.data });
            })
            .catch(() => {
                this.setState({ error: true });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <BetterCircularProgress loading={this.state.loading}>
                {!this.state.error && (
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__number">{this.state.data?.obras_count}</span>
                                            <span className="kt-widget26__desc">{`Obras`}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__number">{this.state.data?.clientes_count}</span>
                                            <span className="kt-widget26__desc">{`Clientes`}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>
                    </div>
                )}
            </BetterCircularProgress>
        );
    }
}
