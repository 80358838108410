export default {
    header: {
        self: {},
        items: [],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
                bullet: "dot",
            },
            {
                title: "Obras",
                root: true,
                icon: "fas fa-hammer",
                page: "obra",
                bullet: "dot",
            },
            {
                title: "Etapa de obra",
                root: true,
                icon: "fas fa-pencil-ruler",
                page: "etapa",
                bullet: "dot",
            },
            {
                title: "Solicitações",
                root: true,
                icon: "fas fa-flag",
                page: "solicitacao",
                bullet: "dot",
            },
            {
                title: "Fornecedores",
                root: true,
                icon: "fas fa-dolly",
                page: "fornecedor",
                bullet: "dot",
            },
            {
                title: "Clientes",
                root: true,
                icon: "fas fa-users",
                page: "cliente",
                bullet: "dot",
            },
            {
                title: "Acessos",
                root: true,
                bullet: "dot",
                icon: "fas fa-user-shield",
                submenu: [
                    {
                        title: "Administradores",
                        bullet: "dot",
                        page: "admin",
                    },
                    {
                        title: "Grupo de usuários",
                        bullet: "dot",
                        page: "grupo-usuario",
                    },
                ],
            },
        ],
    },
};
